import React from 'react'
import {
    Box,
    Flex,
    Button,
    Heading,
    Text
} from '@chakra-ui/react'
import { AnimatePresence, motion } from 'framer-motion'
import { GatsbyImage, getImage, StaticImage } from 'gatsby-plugin-image'
import { PrismicRichText } from '@prismicio/react'

const growLogo = {
    hover: {
      scale: 1.05,
    },
    transition: {
      duration: 0.25,
      ease: "easeInOut",
    },
  };

export default function BestNonProfitForm({ scarecrows, formData, setFormData, x }) {
    const sculptures = scarecrows.scarecrow;
    
    return (
                <>
                    <Heading textAlign="center">
                       Vote for Kids' Choice
                    </Heading>
                    
                    <Flex w="100%" flexWrap="wrap" justifyContent="center">
                        {sculptures.map((item, index) => {
                            return (
                                <Box w={{base: "100%", md: "50%", lg: "33.33%"}} key={index} p="4">
                                    <Box 
                                        //h="100%" 
                                        as={motion.div}
                                        variants={growLogo}
                                        initial="rest"
                                        whileHover="hover"
                                        whileTap={{ scale: 0.8 }}
                                        animate="rest">
                                    <input type="radio" 
                                        name="most_creative"
                                        value={item.scarecrow_id}
                                        id={item.scarecrow_id}
                                        checked={formData.kids_choice === item.scarecrow_id}
                                        onChange={(e) => {
                                            setFormData({
                                            ...formData,
                                           kids_choice: e.target.value,
                                            });
                                        }}
                                    />
                                    
                                    <label htmlFor={item.scarecrow_id}>
                                        <Flex flexDirection="column" boxShadow="md" p="4" borderRadius="md" height="100%" justifyContent="space-between" cursor="pointer">
                                            <Text fontWeight="bold" as="h4" size="lg" mb="2" textAlign="center">
                                                {item.scarecrow_id}
                                            </Text>
                                            <Box mb="2">
                                                <GatsbyImage image={getImage(item.scarecrow_image.localFile)} alt={item.scarecrow_title} />
                                            </Box>
                                            <Box textAlign="center" mt="auto">
                                                <Text fontWeight="900">{item.scarecrow_title}</Text>
                                                <Text mb="0">Team:</Text>
                                                <PrismicRichText field={item.scarecrow_description.richText} />
                                            </Box>
                                            
                                        </Flex>
                                    </label>
                                </Box>
                                </Box>
                            )
                        })
                        }
                    </Flex>
                </>
    )
}