import { 
    useEffect,
    useState,
    useRef,
    useCallback
} from 'react';

function useStickyVote() {
    const [isSticky, setSticky] = useState(false);
    const element = useRef(null);

    const handleScroll = useCallback(() => {
        // element.current returns null on initial render, so check for that
        if (element.current) {
            window.scrollY > element.current.getBoundingClientRect().top + 170
            ? setSticky(true)
            : setSticky(false);
        }
    }, []);

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', () => handleScroll);
        };
    }, [handleScroll]);

    return { isSticky, element };
}

export default useStickyVote;