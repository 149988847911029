import * as React from "react"
import { graphql } from "gatsby"
import Layout from "../../components/layout"
import {
  Heading,
  Container,
  Box,
  Flex,
} from '@chakra-ui/react'

import { PrismicRichText, SliceZone } from '@prismicio/react'
import { components } from "../../components/Slices"
import PageSubMenu from "../../components/PageSubMenu/PageSubMenu"
import ScarecrowForm from "../../components/ScarecrowForm/ScarecrowForm"
import { GatsbyImage, getImage } from "gatsby-plugin-image"


const VotePage = ({ data, location }) => {
  const doc = data.prismicScarecrowPage.data;
  const scarecrows = doc.content_relationship.document?.data;
   
  return (
    <Layout>
      {doc.submenu.document !== null &&
      <PageSubMenu 
        menuItems={doc.submenu.document.data.page_submenu}
        location={location}
      />}
     <main>
        <Container maxW="container.xl">
          <Flex alignItems="center" textAlign="center" flexDirection="column">
            <Box mt="12">
              <Heading as="h1" fontSize="5xl" mb="4">
                {doc.title.text}
              </Heading>
              <Flex flexWrap="wrap" w="100%">
                <Box textAlign={{base: "center", md: "left"}} w={{base: '100%', md: '70%'}}>
                  <PrismicRichText field={doc.page_content.richText} />
                </Box>
              </Flex>
            </Box>
          </Flex>
          {scarecrows && (
            <Box>
            <ScarecrowForm 
              scarecrows={scarecrows}
            />
          </Box>
          )}
        </Container>
        <SliceZone slices={doc.body} components={components} />
      </main>
    </Layout>
  )
}

export const query = graphql`
{
  prismicScarecrowPage(uid: {eq: "vote"}) {
    uid
    data {
      title {
        text
      }
      body {
        ... on PrismicScarecrowPageDataBodyNewsletterSignup {
          id
          slice_type
          primary {
            newsletter_signup_title {
              text
            }
            newsletter_signup_subtitle {
              text
            }
            newsletter_signup_button_label
            newsletter_signup_background_image {
              localFile {
                childImageSharp {
                  gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
                }
              }
            }
          }
        }
        ... on PrismicScarecrowPageDataBodyDowntownEvents {
          id
          primary {
            justify_image
            button_label
            button_link {
              url
            }
            event_content {
              richText
            }
            event_logo {
              localFile {
                childImageSharp {
                  gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
                }
                publicURL
              }
              alt
            }
            event_title {
              text
            }
            image {
              alt
              localFile {
                childImageSharp {
                  gatsbyImageData(aspectRatio: 1, layout: FULL_WIDTH, placeholder: BLURRED)
                }
              }
            }
          }
          items {
            event_fact_title {
              text
            }
            event_fact_stat {
              text
            }
          }
          slice_type
        }
        ... on PrismicScarecrowPageDataBodyCallToAction {
          id
          slice_type
          primary {
            link_label
            link {
              url
            }
            call_to_action_title {
              text
            }
            call_to_action_image {
              alt
              localFile {
                childImageSharp {
                  gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
                }
              }
            }
            call_to_action_content {
              richText
            }
          }
        }
        ... on PrismicScarecrowPageDataBodyRichText {
          id
          slice_type
          items {
            content {
              richText
            }
          }
        }
      }
      submenu {
        document {
          ... on PrismicPageSubmenu {
            id
            data {
              page_submenu {
                menu_item_link {
                  url
                }
                menu_item_label
              }
            }
          }
        }
      }
      page_content {
        richText
      }
      content_relationship {
        document {
          ... on PrismicScarecrowVoting {
            data {
              title {
                text
              }
              content {
                richText
              }
              scarecrow {
                scarecrow_title
                scarecrow_image {
                  alt
                  localFile {
                    childImageSharp {
                      gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
                    }
                  }
                }
                scarecrow_id
                scarecrow_description {
                  richText
                }
                scarecrow_type
              }
            }
          }
        }
      }
    }
  }
}

`

export default VotePage

export const Head = ({ data }) => <title>{data.prismicScarecrowPage.data.title.text}</title>
