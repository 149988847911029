import React, { useState } from "react";
import useStickyVote from "../../hooks/useStickyVote";
import { useForm } from "react-hook-form";
import { Link as GatsbyLink } from "gatsby";
import {
  Box,
  Button,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  Text,
  Heading,
  Container,
  Input,
} from "@chakra-ui/react";
import BestBusinessForm from "./BestBusinessForm";
import BestNonProfitForm from "./BestNonProfitForm";
import SpiritOfTheHarvestForm from "./SpiritOfTheHarvestForm";
import KidsFavoriteForm from "./KidsFavoriteForm";
import DemographicsForm from "./DemographicsForm";
import { ChevronLeftIcon, ChevronRightIcon } from "@chakra-ui/icons";
import MostHumorousForm from "./MostHumorousForm";

export default function ScarecrowForm({ scarecrows }) {
  const { isSticky, element } = useStickyVote();
  const [page, setPage] = useState(0);
  const [formData, setFormData] = useState({
    zip_code: "",
    email: "",
    out_of_town: "",
    business_theme: "",
    nonprofit_theme: "",
    spirit_theme: "",
    kids_choice: "",
    most_humorous: "",
    newsletter: "Yes",
  });
  const [x, setX] = useState("0%");
  const [succeeded, setSucceeded] = useState(false);
  const [processing, setProcessing] = useState(false);

  const {
    handleSubmit,
    reset,
    formState: { isValid, errors },
  } = useForm({
    mode: "onSubmit",
  });

  const SubmitButton = () => (
    <Button variant="scarecrow" type="submit" size="lg" isDisabled={processing}>
      {processing ? "Submitting..." : "Submit"}
    </Button>
  );
  const SuccessMessage = () => (
    <Box>
      <Alert
        status="success"
        variant="subtle"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        textAlign="center"
        height="400px"
        borderRadius="1rem"
      >
        <AlertIcon boxSize="40px" mr={0} />
        <AlertTitle mb="4">Thank you for voting!</AlertTitle>
        <AlertDescription maxWidth="md" mb="4">
          Thank you for your vote! The winners will be announced via social
          media in early November.
        </AlertDescription>
        <AlertDescription maxWidth="md">
          As a 501(c)(3) non-profit organization. We rely on the generosity of
          our community to provide our services. If you would like to make a
          donation, please click the button below. Thank you!
        </AlertDescription>
        <Box my="8">
          <Button
            as={GatsbyLink}
            to="/donate"
            variant="primary"
            _hover={{ color: "white", bg: "brand.400" }}
          >
            Donate Now!
          </Button>
        </Box>
      </Alert>
    </Box>
  );

  const onSubmit = (data) => {
    setProcessing(true);

    // Convert state object to FormData object as required by the Apps Script
    function getFormData(object) {
      const newFormData = new FormData();
      Object.keys(object).forEach((key) =>
        newFormData.append(key, object[key])
      );
      return newFormData;
    }
    let googleData = getFormData(formData);

    //console.log(googleData)

    fetch(
      `https://script.google.com/macros/s/${process.env.GATSBY_SCARECROW_VOTING_URL}/exec`,
      {
        method: "POST",
        body: googleData,
      }
    )
      .then((response) => {
        if (response.status === 200) {
          //console.log(new FormData(votingData))
          setProcessing(false);
          setSucceeded(true);
          reset();
        } else {
          console.log("Error submiting to Google Sheet");
        }
      })
      .catch((error) => console.log("Google error!", error.message));
    setTimeout((data) => {}, 4000);
  };

  const ConditionalComponent = () => {
    switch (page) {
      case 0:
        return (
          <BestBusinessForm
            scarecrows={scarecrows}
            formData={formData}
            setFormData={setFormData}
            page={page}
            x={x}
            setX={setX}
          />
        );
      case 1:
        return (
          <BestNonProfitForm
            scarecrows={scarecrows}
            formData={formData}
            setFormData={setFormData}
            page={page}
            x={x}
            setX={setX}
          />
        );
      case 2:
        return (
          <SpiritOfTheHarvestForm
            scarecrows={scarecrows}
            formData={formData}
            setFormData={setFormData}
            page={page}
            x={x}
            setX={setX}
          />
        );
      case 3:
        return (
          <KidsFavoriteForm
            scarecrows={scarecrows}
            formData={formData}
            setFormData={setFormData}
            page={page}
            x={x}
            setX={setX}
          />
        );
    case 4:
        return (
            <MostHumorousForm
                scarecrows={scarecrows}
                formData={formData}
                setFormData={setFormData}
                page={page}
                x={x}
                setX={setX}
            />
        );
    case 5: 
        return (
            <DemographicsForm
                scarecrows={scarecrows}
                formData={formData}
                setFormData={setFormData}
                page={page}
                x={x}
                setX={setX}
            />
        );
      default:
        return (
          <BestBusinessForm
            scarecrows={scarecrows}
            formData={formData}
            setFormData={setFormData}
            page={page}
            x={x}
            setX={setX}
          />
        );
    }
  };

  const scrollTop = () => {
    window.scrollTo(0, 0);
  };


  return (
    // overflowx hidden to prevent framer motion from causing horizontal scroll
    <Box overflowX="hidden" element={element} sticky={isSticky}>
      <Box mb="8">
        {succeeded ? (
          <SuccessMessage />
        ) : (
          <form
            onSubmit={handleSubmit(onSubmit)}
            id="scarecrow-form"
            name="scarecrow-form"
          >
            <Box ref={element} className={isSticky ? 'sticky-header' : ''} bg="white">
              <Box textAlign="center" mt="0" >
                <Text mb="4">
                  <strong
                    style={{ textTransform: "uppercase", marginBottom: "1rem" }}
                  >
                    Your votes
                  </strong>
                  <br />
                  Best Use of Business Theme:{" "}
                  <strong>{formData.business_theme}</strong>
                  <br />
                  Best Use of Nonprofit Theme:{" "}
                  <strong>{formData.nonprofit_theme}</strong>
                  <br />
                  Best "Spirit of the Harvest" Theme:{" "}
                  <strong>{formData.spirit_theme}</strong>
                  <br />
                  Kids' Choice: <strong>{formData.kids_choice}</strong>
                  <br />
                  Most Humorous: <strong>{formData.most_humorous}</strong>
                </Text>
              </Box>
              <Box my="4" textAlign="center">
                {page > 0 && (
                  <Button
                    variant="scarecrow"
                    onClick={() => {
                      setPage(page - 1);
                      setX("-100%");
                      scrollTop();
                    }}
                    mx="2"
                    size="lg"
                  >
                    <ChevronLeftIcon ml="-2" /> Back
                  </Button>
                )}
                { page === 0 ? (
                  <Button
                    variant="scarecrow"
                    onClick={() => {
                      setPage(page + 1);
                      setX("100%");
                      scrollTop();
                    }}
                    mx="2"
                    size="lg"
                    disabled={!formData.zip_code}
                  >
                    <span>Next</span> <ChevronRightIcon mr="-2" />
                  </Button>
                ) :
                page === 1 ? (
                  <Button
                    variant="scarecrow"
                    onClick={() => {
                      setPage(page + 1);
                      setX("100%");
                      scrollTop();
                    }}
                    mx="2"
                    size="lg"
                    disabled={!formData.zip_code}
                  >
                    <span>Next</span> <ChevronRightIcon mr="-2" />
                  </Button>
                ) :
                page === 2 ? (
                  <Button
                    variant="scarecrow"
                    onClick={() => {
                      setPage(page + 1);
                      setX("100%");
                      scrollTop();
                    }}
                    mx="2"
                    size="lg"
                    disabled={!formData.zip_code}
                  >
                    <span>Next</span> <ChevronRightIcon mr="-2" />
                  </Button>
                ) : page === 3 ? (
                  <Button
                    variant="scarecrow"
                    onClick={() => {
                      setPage(page + 1);
                      setX("100%");
                      scrollTop();
                    }}
                    mx="2"
                    size="lg"
                    disabled={!formData.zip_code}
                  >
                    <span>Next</span> <ChevronRightIcon mr="-2" />
                  </Button>
                ) : page === 4 ? (
                  <Button
                    variant="scarecrow"
                    onClick={() => {
                      setPage(page + 1);
                      setX("100%");
                      scrollTop();
                    }}
                    mx="2"
                    size="lg"
                    disabled={!formData.zip_code}
                  >
                    <span>Next</span> <ChevronRightIcon mr="-2" />
                  </Button>
                )
                : (
                  <SubmitButton />
                )}
              </Box>
            </Box>
            {ConditionalComponent()}
          </form>
        )}
      </Box>
    </Box>
  );
}
